/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check-mark': {
    width: 51,
    height: 51,
    viewBox: '0 0 51 51',
    data: '<path pid="0" d="M35.428 18.579c.563.56.563 1.477 0 2.04L23.626 32.42a1.442 1.442 0 01-2.04 0l-6.014-6.014a1.443 1.443 0 012.04-2.04l4.994 4.992 10.782-10.78a1.443 1.443 0 012.04 0zM51 25.5C51 39.561 39.561 51 25.5 51S0 39.561 0 25.5 11.439 0 25.5 0 51 11.439 51 25.5zm-2.887 0c0-12.47-10.145-22.613-22.613-22.613-12.469 0-22.613 10.142-22.613 22.613 0 12.47 10.144 22.613 22.613 22.613 12.468 0 22.613-10.142 22.613-22.613z" _fill="#fff"/>'
  }
})
